import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import ModalWrapper, { ModalWidth } from './ModalWrapper'
import ModalCta from './ModalCta'
import ModalHeader from './ModalHeader'
import { CrossIcon } from 'components/shared/Icons'
import { breakpoints } from 'utils/styles'
import Portal from 'components/shared/Portal'

class Modal extends React.Component {
  state = {
    isAnimating: this.props.show || false,
    isShown: this.props.show || false,
    blurContainers: this.props.blurContainerSelector
      ? Array.from(document.querySelectorAll(this.props.blurContainerSelector))
      : [],
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        isAnimating: true,
        isShown: nextProps.show,
      })
    }
  }

  close = () => {
    this.props.onClose()
    console.log()
  }

  render() {
    const {
      children,
      cta,
      hasLogo,
      image,
      isWide,
      onClose,
      show,
      title,
    } = this.props

    return (
      <Portal>
        <ModalWrapper
          cta={cta}
          onClose={onClose}
          show={show}
          width={isWide ? ModalWidth.Wide : ModalWidth.Normal}
          image={image}
          fullscreenResponsiveModal
        >
          <CloseButton onClick={this.close}>
            <CrossIcon />
          </CloseButton>

          {image && <Image image={image} />}

          <ModalContent cta={cta} hasImage={image && true}>
            {(hasLogo || title) && (
              <ModalHeader title={title} hasLogo={hasLogo} />
            )}

            <div>{children}</div>

            {cta && (
              <ModalCta
                cta={cta}
                hasImage={image && true}
                onClose={this.close}
              />
            )}
          </ModalContent>
        </ModalWrapper>
      </Portal>
    )
  }
}

export const ModalContent = styled('div')`
  box-sizing: border-box;
  padding: ${props =>
    props.cta
      ? props.cta.isFixed
        ? '20px 40px 100px'
        : '20px 40px 0'
      : '20px 40px 40px'};
  width: ${props => (props.hasImage ? '50%' : '100%')};
  margin-left: ${props => props.hasImage && '50%'};
  @media (max-width: ${breakpoints.phoneOnly}) {
    margin-left: 0;
    width: 100%;
    padding: ${props =>
      props.cta && props.cta.isFixed ? '20px 20px 110px' : '20px'}};
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.5);
`

export const CloseButton = styled('div')`
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
  svg {
    height: 15px;
  }
`

const Image = styled('div')`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  border-radius: 5px 0 0 5px;
  @media (max-width: ${breakpoints.phoneOnly}) {
    display: none;
  }
`

Modal.defaultProps = {
  show: false,
  blurContainerSelector: '',
}

Modal.propTypes = {
  blurContainerSelector: PropTypes.string,
  cta: PropTypes.shape({
    isFixed: PropTypes.bool,
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  onClose: PropTypes.func,
  hasLogo: PropTypes.bool,
  isWide: PropTypes.bool,
  image: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
}

export default Modal
