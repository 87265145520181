import React, { useCallback, useState } from 'react'
import { navigate } from 'gatsby'

import styles from './ItemRentBox.module.scss'
import Button from 'components/shared/Button'
import ShareBtn from 'components/shared/ShareBtn'
import { deepClean, getRoute } from 'utils'
import { ReportIcon } from 'components/shared/Icons'
import DatePicker from 'components/shared/DatePicker/DatePicker'
import { Alert } from 'components/shared/Forms'
import Modal from 'components/shared/Modal'

/**
 * Determines if a particular day on the calendar is blocked
 * @param {Moment.moment} moment
 * @param {Array} availability
 */
function isDayBlocked(moment, availability) {
  if (!availability || availability.length == 0) {
    return false
  }
  return availability.some(({ start_date, end_date }) => {
    // TODO: Determine granularity better
    if (moment.isBetween(start_date, end_date, null, '[]')) {
      return true
    }
    return false
  })
}

/**
 * Determines if the range of selected days has blocked days in it
 */
function isBlockedDateInRange(availability, startDate, endDate) {
  return availability.some(({ start_date, end_date }) => {
    return (
      Moment(start_date).isBetween(startDate, endDate) ||
      Moment(end_date).isBetween(startDate, endDate)
    )
  })
}

function DateSubmit({ onSubmit, availability }) {
  const [[startDate, endDate], setDates] = useState([null, null])
  const [focusedInput, setFocusedInput] = useState(null)

  const [alert, setAlert] = useState({})
  const selectDates = useCallback(
    ({ startDate, endDate }) => {
      setAlert({})
      if (startDate && endDate) {
        if (isBlockedDateInRange(availability, startDate, endDate)) {
          return setAlert({
            type: 'error',
            message:
              'The item is already booked for one or more days in the range you selected.',
            visible: true,
          })
        }
      }
      setDates([startDate, endDate])
    },
    [availability]
  )

  const submitForm = useCallback(() => {
    if (!startDate || !endDate) {
      return setAlert({
        type: 'warning',
        message: 'Please select a start and end date.',
        visible: true,
      })
    }
    typeof onSubmit === 'function' && onSubmit(startDate, endDate)
  }, [startDate, endDate])

  return (
    <div style={{ width: '100%' }}>
      <Alert
        className={styles.alert}
        type={alert.type}
        visible={alert.visible}
        message={alert.message}
      />
      <div className={styles.dateContainer}>
        <DatePicker
          startDate={startDate}
          startDateId="start_date"
          endDate={endDate}
          endDateId="end_date"
          onDatesChange={selectDates}
          readOnly={true}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          isDayBlocked={moment => isDayBlocked(moment, availability)}
        />
      </div>
      <Button full big onClick={() => submitForm()}>
        Rent
      </Button>
    </div>
  )
}

export default props => {
  const { onSubmit, availability, item, isStuck } = props
  const { name, id } = item

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.label}>
            <span>Price</span>
          </div>

          <div className={styles.amount}>
            <span>{item.price.formatted.day}</span>
          </div>
        </div>
        <div className={styles.rentButtonContainer}>
          <div
            className={[styles.detailsWrapper, isStuck && styles.stuck].join(
              ' '
            )}
          >
            <div className={styles.detailsContainer}>
              <h1 className={styles.title}>{item.name}</h1>
            </div>
          </div>

          <DateSubmit onSubmit={onSubmit} availability={availability} />
        </div>
        <div className={styles.shareContainer}>
          <ShareBtn
            name="item"
            text={'Rent ' + name + ' on Borrowed'}
            link={getRoute('ITEM', { id })}
          />
        </div>
      </div>
      <div
        className={styles.reportContainer}
        onClick={() => console.log('implement report listing')}
      >
        <ReportIcon />
        <div className={styles.reportBtn}>
          <span>Report Listing</span>
        </div>
      </div>
    </div>
  )
}

export const RentBoxMobile = props => {
  const { onSubmit, availability, item, isStuck } = props
  const { price, pictures, name } = item
  const [isOpen, setOpen] = useState(false)
  return (
    <div className={styles.wrapper}>
      <div className={styles.containerMobile}>
        <div className={styles.priceContainer}>
          <span>{price.formatted.day}</span>
        </div>
        <div className={styles.rentButton}>
          <Button full big onClick={() => setOpen(true)}>
            Rent
          </Button>
        </div>
      </div>
      <Modal
        hasLogo={true}
        hasImage={true}
        image={pictures[0]}
        title={`${name}`}
        onClose={() => setOpen(false)}
        show={isOpen}
      >
        <DateSubmit onSubmit={onSubmit} availability={availability} />
      </Modal>
    </div>
  )
}
