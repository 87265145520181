import React from 'react'

import styles from './Section.module.scss'
import SmoothSlider from 'components/shared/Slider/SmoothSlider'
import ItemDisplay from 'components/Displays/ItemDisplay'
import IntersectionScroll from 'components/shared/IntersectionScroll'

const MoreItems = ({ header, items }) => {
  if (!items.length) {
    return <div />
  }
  return (
    <div>
      <h2 className={styles.header}>{header}</h2>
      <div className={styles.body}>
        <SmoothSlider>
          {items.map(item => (
            <div key={item.id} className="slider-item">
              <ItemDisplay product={item} showRent={false} hideCity={true} />
            </div>
          ))}
        </SmoothSlider>
      </div>
    </div>
  )
}

export default function MoreSection({
  user = {},
  ownerItems = [],
  recommendedItems = [],
  updateCurrentSection,
  updateRefMap,
} = {}) {
  return (
    <IntersectionScroll
      id="more"
      observeOnScroll
      updateCurrentSection={updateCurrentSection}
      updateRefMap={updateRefMap}
      className={[styles.section, styles.moreSection].join(' ')}
    >
      <MoreItems items={recommendedItems} header="People also viewed" />
      <MoreItems items={ownerItems} header={`${user.firstName} also owns`} />
    </IntersectionScroll>
  )
}
