import React from 'react'

import ReviewList from 'components/Reviews/ReviewList'
import styles from './Section.module.scss'
import IntersectionScroll from 'components/shared/IntersectionScroll'

export default ({ reviews, updateCurrentSection, updateRefMap }) => (
  <IntersectionScroll
    id="reviews"
    observeOnScroll
    updateCurrentSection={updateCurrentSection}
    updateRefMap={updateRefMap}
    className={styles.section}
  >
    <div className={styles.description}>
      <h2 className={styles.header}>Reviews</h2>
      <div className={styles.body}>
        <ReviewList reviews={reviews} className={styles.reviewContainer} />
      </div>
    </div>
  </IntersectionScroll>
)
