import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import styles from './ImageSection.module.scss'

import { getPictureID } from 'utils/conversions'

class ItemImages extends React.Component {
  state = {
    showLightbox: false,
    selectedImage: 0,
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({
      showLightbox: !this.state.showLightbox,
      selectedImage: index,
    })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 })
  }

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < this.props.images.length - 1) {
          this.setState({ selectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }

  render() {
    const { pictures, onClick } = this.props
    const { showLightbox, selectedImage } = this.state

    return (
      <div className={styles.imageWrapper}>
        <div className={styles.grid}>
          <div
            className={styles.featuredImage}
            style={{ width: pictures[1] ? '67%' : '100%' }}
          >
            <div
              className={styles.imageBox}
              css={{
                backgroundImage: `url(https://ucarecdn.com/${getPictureID(
                  pictures[0]
                )}/-/preview/1300x900/-/quality/lighter/-/format/jpg/-/progressive/yes/)`,
              }}
              onClick={e => this.handleClick(e, 0)}
            />
          </div>
          {pictures[1] && (
            <div className={styles.secondaryImages}>
              <div
                style={{ height: pictures[2] ? '175px;' : '351px' }}
                className={[styles.imageBox, styles.top].join(' ')}
                css={{
                  backgroundImage: `url(https://ucarecdn.com/${getPictureID(
                    pictures[1]
                  )}/-/preview/900x300/-/quality/lighter/-/format/jpg/-/progressive/yes/)`,
                }}
                onClick={e => this.handleClick(e, 1)}
              />
              {pictures[2] && (
                <div
                  className={styles.imageBox}
                  css={{
                    backgroundImage: `url(https://ucarecdn.com/${getPictureID(
                      pictures[2]
                    )}/-/preview/900x300/-/quality/lighter/-/format/jpg/-/progressive/yes/)`,
                  }}
                  onClick={e => this.handleClick(e, 2)}
                />
              )}
            </div>
          )}
        </div>
        <LightboxModal
          visible={showLightbox}
          onKeyUp={e => this.handleKeyDown(e)}
        >
          <LightboxContent>
            <StyledImg src={pictures[selectedImage]} />
            <Controls>
              <Button onClick={this.closeModal}>Close</Button>
              <LeftRight>
                <Button onClick={this.goBack} disabled={selectedImage === 0}>
                  Previous
                </Button>
                <Button
                  onClick={this.goForward}
                  disabled={selectedImage === pictures.length - 1}
                >
                  Next
                </Button>
              </LeftRight>
            </Controls>
          </LightboxContent>
        </LightboxModal>
      </div>
    )
  }
}

const StyledImg = styled('img')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%; // or whatever
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
  }
  grid-gap: 15px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`

const GalleryItem = styled.div`
  position: relative;
`

const Button = styled.button``

const LightboxModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`
const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  width: 100%;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftRight = styled.div`
  button:first-of-type {
    margin-right: 10px;
  }
`

export default ItemImages
