import React from 'react'

import styles from './Section.module.scss'
import UserBlurb from 'components/Users/UserBlurb'
import IntersectionScroll from 'components/shared/IntersectionScroll'

export default ({ user, updateCurrentSection, updateRefMap }) => (
  <IntersectionScroll
    id="owner"
    observeOnScroll
    updateCurrentSection={updateCurrentSection}
    updateRefMap={updateRefMap}
    className={styles.section}
  >
    <div className={styles.description}>
      <h2 className={styles.header}>About the owner</h2>
      <div className={styles.body}>
        <UserBlurb user={user} showName showMessage />
      </div>
    </div>
  </IntersectionScroll>
)
