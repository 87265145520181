import React from 'react'

import styles from './ReviewList.module.scss'
import Review from './Review'
import { EmptyIcon } from 'components/shared/Icons'
import List from 'components/shared/Lists/List'

const EmptyContainer = props => (
  <div className={styles.emptyContainer}>
    <div className={styles.iconContainer}>
      <EmptyIcon />
    </div>
  </div>
)

export default ({ reviews, loading, className }) => {
  if (!reviews) {
    return <div />
  }

  return (
    <List
      items={reviews}
      itemComponent={Review}
      loading={loading}
      emptyComponent={EmptyContainer}
      className={className}
      showPowered={false}
      showTotal={false}
    />
  )
}
