import React, { useState, useCallback } from 'react'
import { Match } from '@reach/router'
import { navigate } from 'gatsby'

import ItemPage from 'components/ItemPage'
import SEO from 'components/shared/SEO'
import Layout from 'layouts/Page'
import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import { DatabaseService } from 'services'
import DatabasePreloader from 'components/shared/DatabasePreloader'
import { useNavbarConfig } from 'components/Navbar/NavbarConfig'
import { deepClean } from 'utils'

function ItemBase(props) {
  const { item } = props

  const { id, user } = item

  const [availability, setAvailability] = useState(null)
  // load availability
  useAsyncEffect(async () => {
    const availability = await DatabaseService.getItemAvailability(id)
    setAvailability(availability)
  }, [id])

  const [ownerItems, setOwnerItems] = useState([])
  // Loads the user's items
  // maybe do this when the user scrolls
  // to speed up performance
  useAsyncEffect(async () => {
    try {
      /** @type {firebase.firestore.QueryDocumentSnapshot} */
      const qSnap = await DatabaseService.getFleetByOwner(user.id)
      const data = qSnap.docs.map(docSnap => {
        return { id: docSnap.id, ...docSnap.data() }
      })
      setOwnerItems(data)
    } catch (error) {
      console.error(`Error getting documents: ${error}`)
    }
  }, [id])

  const [reviews, setReviews] = useState(null)

  useAsyncEffect(async () => {
    try {
      const qSnap = await DatabaseService.getReviewsByItem(item.id)
      const data = qSnap.docs.map(docSnap => {
        return { id: docSnap, ...docSnap.data() }
      })
      setReviews(data)
    } catch (error) {
      console.error(`Error getting documents${error}`)
    }
  })

  const onSubmit = useCallback(
    (startDate, endDate) => {
      // Object has to be cleaned before being passed via state
      // since having references causes circular structures which
      // also causes a silent failure
      const cleanItem = deepClean(item)

      const start_date = startDate._d
      const end_date = endDate._d

      const num_days = endDate.diff(startDate, 'days')
      const total_price_nofee = (cleanItem.price_d / 100) * num_days
      const service_fee = total_price_nofee * 0.15
      const total_price = total_price_nofee + service_fee

      const start_formatted = start_date.valueOf()
      const end_formatted = end_date.valueOf()

      const rental = {
        total_price_nofee,
        service_fee,
        total_price,
        start_date: start_formatted,
        end_date: end_formatted,
        num_days,
        item: cleanItem,
        item_name: cleanItem.name,
        price_d: cleanItem.price_d,
        item_img: cleanItem.img,
        lender: cleanItem.user,
        lender_name: cleanItem.user.firstName,
      }
      navigate('/rent-item', { state: { rental } })
    },
    [item]
  )

  return (
    <ItemPage
      item={item}
      availability={availability}
      reviews={reviews}
      ownerItems={ownerItems}
      onSubmit={onSubmit}
    />
  )
}

export default ({ location }) => {
  useNavbarConfig({ transparent: true })
  return (
    <Match path={'/item/:id/*'}>
      {({ match, location }) => (
        <DatabasePreloader
          match={match}
          map={`/fleet/:id`}
          populate={{
            fields: ['user'],
          }}
        >
          {item => (
            <Layout location={location}>
              <SEO
                title={`Rent ${item.name} for \$${item.price_d / 100} / day`}
                image={item.img}
                pathname={location.pathname}
              />
              <ItemBase item={item} />
            </Layout>
          )}
        </DatabasePreloader>
      )}
    </Match>
  )
}
