import React from 'react'
import styled from '@emotion/styled'

import Borrowed from 'components/shared/Brand/Borrowed'
import { breakpoints } from 'utils/styles'

/* consideration: logo can be swapped for other company logos */

export default ({ hasLogo, title }) => {
  return (
    <Header>
      {hasLogo && <Borrowed />}
      {title && (
        <Title size="5" weight="semibold">
          {title}
        </Title>
      )}
    </Header>
  )
}

const Logo = styled('svg')`
  display: block;
  line-height: 1em;
  @media (max-width: ${breakpoints.phoneOnly}) {
    display: none;
  }
`

const Title = styled('h2')`
  font-family: 'Futura';
  margin-bottom: 10px;
`

const Header = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
`
