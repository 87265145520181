import React from 'react'
import ReactStars from 'react-stars'
import { navigate } from 'gatsby'

import styles from './ItemDisplay.module.scss'
import { getRoute } from 'utils'
import { convertToMoney } from 'utils/conversions'

export default ({
  showProfile = true,
  showStars = true,
  showCity = true,
  product = {},
  onMouseOver,
  onMouseOut,
}) => {
  const { id, name, price = {}, user = {}, city, state, pictures } = product
  const { firstName } = user
  return (
    <div
      className={styles.productContainer}
      onMouseOver={() => typeof onMouseOver === 'function' && onMouseOver(id)}
      onMouseOut={onMouseOut}
    >
      <div
        onClick={() => navigate(getRoute('ITEM', { id }))}
        className={styles.productContainer}
      >
        <div className={styles.imgContainer}>
          {pictures && <img src={pictures[0]} className={styles.coverImg} />}
        </div>
        <div className={styles.description}>
          <h4 className={styles.title}>{name}</h4>
          <div className={styles.price}>
            {convertToMoney(price.day)} per day
          </div>
          {showProfile && user && (
            <div
              className={styles.nameCity}
              onClick={e => {
                navigate(getRoute('PROFILE', { id: user.id }))
                e.stopPropagation()
              }}
            >
              {firstName && <div className={styles.name}>{firstName}</div>}
              {showCity && (
                <div className={styles.city}>{`(${city}, ${state})`}</div>
              )}
            </div>
          )}
          <div
            className={styles.reviewContainer}
            style={{
              visibility:
                showStars && user.rating_count > 0 ? 'block' : 'hidden',
            }}
          >
            <ReactStars
              count={5}
              value={user.avg_rating}
              edit={false}
              size={12}
              color1={'#efeded'}
              color2={'#7480f7'}
            />
            <div className={styles.reviewCount}>({user.rating_count})</div>
          </div>
        </div>
      </div>
    </div>
  )
}
