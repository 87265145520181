import React from 'react'

export default class IntersectionScroll extends React.Component {
  static defaultProps = {
    observeOnScroll: false,
    updateCurrentSection: () => {},
    updateRefMap: () => {},
  }

  constructor(props) {
    super(props)

    this.sectionRef = React.createRef()
  }

  componentDidMount() {
    if (!this.props.observeOnScroll) {
      return
    }

    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(({ target, boundingClientRect }) => {
          if (boundingClientRect.top <= 400 && boundingClientRect.top >= 0) {
            const section = target.parentNode
            this.props.updateCurrentSection(section.id)
          }
        })
      },
      { rootMargin: '-150px 0px 0px 0px', threshold: 0.02 }
    )

    this.observer.observe(this.sectionRef.current)

    this.props.updateRefMap(
      this.sectionRef.current.parentNode.id,
      this.sectionRef.current.parentNode
    )
  }

  render() {
    const { className, id = '', children } = this.props

    return (
      <section className={className} id={id}>
        <div ref={this.sectionRef} />
        {children}
      </section>
    )
  }
}
