import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

import styles from './Section.module.scss'
import MapStyle from 'components/Map/MapStyle'
import IntersectionScroll from 'components/shared/IntersectionScroll'
import keys from 'constants/keys'

const CIRCLE_ICON = {
  path: 'M 0, 0 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
  fillColor: '#56578D',
  fillOpacity: 0.3,
  strokeColor: '#56578D',
  strokeWidth: 5,
}

const ItemSingleMap = withScriptjs(
  withGoogleMap(props => {
    let center = { lat: props.location._lat, lng: props.location._long }
    return (
      <GoogleMap
        defaultZoom={14}
        defaultOptions={{
          styles: MapStyle,
          draggable: false,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        zoomControl={false}
        center={props.location}
      >
        <Marker position={props.location} icon={CIRCLE_ICON} />
      </GoogleMap>
    )
  })
)

export default function ItemLocationMap({
  _geoloc = {},
  updateCurrentSection,
  updateRefMap,
}) {
  return (
    <IntersectionScroll
      id="location"
      observeOnScroll
      updateCurrentSection={updateCurrentSection}
      updateRefMap={updateRefMap}
      className={styles.section}
    >
      <h2 className={styles.header}>Location</h2>
      <div className={styles.body}>
        <ItemSingleMap
          location={_geoloc}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
            keys.GOOGLE_MAPS.API_KEY
          }&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              className="product-map-container"
              style={{ height: `250px`, width: `100%`, borderRadius: `6px` }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    </IntersectionScroll>
  )
}
