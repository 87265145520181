import React, { useState, useEffect } from 'react'
import useResizeObserver from 'use-resize-observer'
import {
  DayPickerRangeController,
  DateRangePicker,
  CalendarDay,
  SingleDatePicker,
} from 'react-dates'

import './DatePicker.scss'

// prevents undefined modifiers from crashing functions
const safeModifiers = modifiers => {
  return modifiers ? modifiers : new Set()
}

export default function DatePicker(props) {
  const {
    children,
    collapseAt = 800, //px
    collapseLabel = 0,
    sidebarAt = collapseAt,
    data = [],
    type = 'range',
    ...containerProps
  } = props
  const [numMonths, setMonths] = useState(2)
  const [ref, width] = useResizeObserver()

  useEffect(() => {
    if (width <= 1) {
      return
    }
    if (width <= collapseAt) {
      setMonths(1)
    } else {
      setMonths(2)
    }
  }, [width])

  return (
    <div ref={ref} {...containerProps}>
      {type == 'range' ? (
        <DateRangePicker
          {...props}
          numberOfMonths={numMonths}
          orientation="horizontal"
          renderCalendarDay={({ modifiers, ...props }) => {
            return (
              <CalendarDay modifiers={safeModifiers(modifiers)} {...props} />
            )
          }}
        />
      ) : (
        <SingleDatePicker withPortal={false} {...props} />
      )}
    </div>
  )
}
