import React, { useState } from 'react'

import styles from './ItemStickyHeader.module.scss'
import TabNavigator from 'components/shared/TabNavigator'
import ItemRentBox, { RentBoxMobile } from './ItemRentBox'
import StickySentinal from 'components/shared/StickySentinal'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import scrollToAnchor from 'utils/scrollToAnchor'

const tabs = [
  {
    content: 'Description',
    val: 'description',
  },
  {
    content: 'Location',
    val: 'location',
  },
  {
    content: 'The Owner',
    val: 'owner',
  },
  {
    content: 'Reviews',
    val: 'reviews',
  },
  {
    content: 'More',
    val: 'more',
  },
]

const matchIndex = val => {
  return tabs.findIndex(t => t.val === val)
}

export default ({
  currentSection,
  availability,
  item,
  children,
  refs,
  onClick,
  onSubmit,
  ...rest
}) => {
  const [isStuck, setStuck] = useState(false)
  const isDesktop = useMediaQuery(`(min-width: 1200px)`)

  return (
    <div className={styles.stickyContainer}>
      <StickySentinal onStickyChange={e => setStuck(e)} />
      <div className={styles.stickyHeader}>
        <div className={styles.stickyHeaderNav}>
          <div className={styles.nav}>
            <TabNavigator
              tabs={tabs}
              tabContainerClass={styles.tabContainer}
              activeTabClass={styles.active}
              selected={matchIndex(currentSection)}
              onSelectTab={tab => onClick(tabs[tab].val)}
            />
          </div>
          {isDesktop && (
            <ItemRentBox
              onSubmit={onSubmit}
              availability={availability}
              item={item}
              isStuck={isStuck}
            />
          )}
        </div>
      </div>
      <div className={styles.mainWrapper}>
        <div className={styles.mainContainer}>
          {children}
          {!isDesktop && (
            <RentBoxMobile
              item={item}
              onSubmit={onSubmit}
              availability={availability}
            />
          )}
        </div>
      </div>
    </div>
  )
}
