import React from 'react'
import ReactStars from 'react-stars'

import styles from './Review.module.scss'
import UserBlurb from 'components/Users/UserBlurb'
import { toDate } from 'utils/conversions'

export default ({ data }) => {
  if (!data) {
    return <div />
  }
  const {
    renter_name,
    renter_img,
    renter,
    rating,
    createdAt,
    description,
  } = data
  const renter_id = renter.id
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.userContainer}>
            <div className={styles.avatar}>
              <UserBlurb
                user={{
                  id: renter_id,
                  picture: renter_img,
                  firstName: renter_name,
                }}
              />
            </div>
            <div className={styles.detailsContainer}>
              <div className={styles.name}>{renter_name}</div>
              <div className={styles.rating}>
                <ReactStars
                  count={5}
                  value={rating || 5}
                  edit={false}
                  size={16}
                  color1={'#efeded'}
                  color2={'#7480f7'}
                />
              </div>
            </div>
          </div>
          <div className={styles.date}>{toDate(createdAt, 'MMM YYYY')}</div>
        </div>
        <div className={styles.body}>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}
