import React from 'react'

import styles from './ItemHeader.module.scss'
import { ImageSection } from './Sections'

export default ({ item }) => {
  const { pictures, name, city } = item
  const id = pictures[0]
    .split('.com/')
    .pop()
    .split('/')[0]
  return (
    <div className={styles.headerWrapper}>
      <div
        className={styles.headerContainer}
        css={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(https://ucarecdn.com/${id}/-/resize/1440x/-/enhance/50/-/blur/500/-/quality/lighter/-/format/jpg/-/progressive/yes/)`,
        }}
      >
        <div className={styles.headerContent}>
          <ImageSection pictures={pictures} />
        </div>
      </div>
    </div>
  )
}
