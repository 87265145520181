import React from 'react'

import styles from './Section.module.scss'
import { MapMarkerIcon } from 'components/shared/Icons'

export default ({ item }) => {
  let { name, city } = item
  return (
    <div className={[styles.section, styles.titleSection].join(' ')}>
      <div className={styles.description}>
        <h2 className={styles.title}>{name}</h2>
        <div className={styles.location}>
          <MapMarkerIcon height="15px" /> {city}
        </div>
      </div>
    </div>
  )
}
