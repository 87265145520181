import React from 'react'

import styles from './ItemPage.module.scss'
import {
  DescriptionSection,
  MapSection,
  MoreSection,
  OwnerSection,
  ReviewsSection,
  TitleSection,
} from './Sections'
import ItemHeader from './ItemHeader'
import ItemStickyHeader from './ItemStickyHeader'
import scrollToAnchor from 'utils/scrollToAnchor'

export default class ItemPage extends React.Component {
  state = {
    currentSection: '',
    refMap: {},
  }

  updateCurrentSection = sectionID => {
    this.setState({ currentSection: sectionID })
  }

  updateRefMap = (sectionID, sectionRef) => {
    this.setState(state => ({
      refMap: {
        ...state.refMap,
        [sectionID]: sectionRef,
      },
    }))
  }

  onClickHandler = targetID => {
    const target = this.state.refMap[targetID] || false
    return scrollToAnchor(
      target,
      () => {
        this.updateCurrentSection(targetID)
      },
      150
    )()
  }

  render() {
    const { currentSection } = this.state
    const { availability, item, ownerItems, reviews, onSubmit } = this.props
    const { description, user, _geoloc } = item

    return (
      <div className={styles.itemPage}>
        <ItemHeader item={item} />
        <ItemStickyHeader
          item={item}
          availability={availability}
          onClick={this.onClickHandler}
          onSubmit={onSubmit}
          currentSection={currentSection}
        >
          <TitleSection item={item} />
          <DescriptionSection
            description={description}
            updateCurrentSection={this.updateCurrentSection}
            updateRefMap={this.updateRefMap}
          />
          <MapSection
            _geoloc={_geoloc}
            updateCurrentSection={this.updateCurrentSection}
            updateRefMap={this.updateRefMap}
          />
          <OwnerSection
            user={user}
            updateCurrentSection={this.updateCurrentSection}
            updateRefMap={this.updateRefMap}
          />
          <ReviewsSection
            reviews={reviews}
            updateCurrentSection={this.updateCurrentSection}
            updateRefMap={this.updateRefMap}
          />
          <MoreSection
            user={user}
            ownerItems={ownerItems}
            updateCurrentSection={this.updateCurrentSection}
            updateRefMap={this.updateRefMap}
          />
        </ItemStickyHeader>
      </div>
    )
  }
}
